
// https://fontsarena.com/metropolis-by-chris-simpson/

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/WOFF2/Metropolis-Regular.woff2) format('woff2');
}


@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/WOFF2/Metropolis-Bold.woff2) format('woff2');
}