@import '../../static/css/mixins.scss';


.ft {
  @include moduleGap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  &__r1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__logoText {
    width: 129px;
    height: auto;
  }
  &__logo {
    display: none;
  }
  &__lauchApp {
    margin-top: 40px;
    margin-bottom: 24px;
  }
  &__copyright {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    color: var(--text-secondary);
  } 
}


@media (max-width: 859.98px) {
  .ft {
    @include moduleGap(0);
    &__logoText {
      display: none;
    }
    &__logo {
      display: block;
    }
    &__r1 {
      flex-direction: column;
    }
    &__lauchApp {
      margin-bottom: 48px;
    }
    &__lang {
      &__list {
        right: initial;
        left: 50%;
        transform: translateX(-50%);
      }
      &__curLang {
        &__text {
          display: block;
          margin-left: 10px;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}